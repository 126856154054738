import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';

// Components
import Button from '../Button/index';
import InputField from '../InputField/index';
import SpinnerWrapper from '../Spinner';

// Utils
import { setUserAccountPassword, getUserAccountPassword } from '../../shared/api/user.api';

import './styles.scss';

const PASSWORD_VALIDATOR = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{12,})/;

const checkFirstPasswordField = value => {
  if (!value) {
    return { level: 'error', msg: 'Ce champ ne peut pas être vide' };
  }
  if (!value.match(PASSWORD_VALIDATOR)) {
    return {
      level: 'error',
      msg: 'Saisissez 12 caractères minimum, au moins une minuscule, une majuscule et un chiffre',
    };
  }

  return { level: '', msg: '' };
};

const checkSecondPasswordField = value => {
  const pwdField1 = document.getElementById('login-form__set-password-1').value;

  if (value !== pwdField1) {
    return { level: 'error', msg: 'Le mot de passe doit être identique' };
  }

  return { level: '', msg: '' };
};

const SetPassword = props => {
  const { match, setOriginalJourneyId, setJourney, history, setUserEmail, user, location } = props;
  const [loadingInProgress, setLoadingInProgress] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [feedbackField1, setFeedbackField1] = useState({
    level: 'warning',
    msg: 'Saisissez 12 caractères minimum, au moins une minuscule, une majuscule et un chiffre',
  });
  const [feedbackField2, setFeedbackField2] = useState({ level: '', msg: '' });

  useEffect(() => {
    const queryValues = queryString.parse(location.search);
    getUserAccountPassword(match.params.token).catch(() => {
      if (queryValues.action === 'set') {
        // If user already sets his password, he is redirecting to exact path to continue his investment
        if (queryValues.journey && queryValues.investmentLinkId) {
          setOriginalJourneyId(queryValues.investmentLinkId);
          setJourney(queryValues.journey);
          history.push(`/${queryValues.journey}/${queryValues.investmentLinkId}`);
          setUserEmail(queryValues.email);
        }
        // For advisor, if he clicks twice on button in email, he is redirecting to /login
        if (!queryValues.journey && !queryValues.investmentLinkId) {
          history.push('/login');
          setUserEmail(queryValues.email);
        }
      } else {
        setErrorMessage(
          'Ce lien a déjà été utilisé et est désormais invalide, merci de faire une nouvelle demande.',
        );
      }
    });
  }, []);

  // make the request to set or reset the password
  const setPassword = () => {
    const password = document.getElementById('login-form__set-password-1').value;
    const queryValues = queryString.parse(location.search);
    // this will display the loading icon until the request comes through
    setLoadingInProgress(true);

    setUserAccountPassword(password, match.params.token)
      .then(res => {
        setLoadingInProgress(false);
        if (props.callback) {
          props.callback();
        }
        setUserEmail(user.email ? user.email : queryValues.email);

        const respData = res.data;
        if (respData && respData.redirection_url) {
          if (queryValues.investmentLinkId && queryValues.journey) {
            setOriginalJourneyId(queryValues.investmentLinkId);
            setJourney(queryValues.journey);
          }
          window.location = respData.redirection_url;
        } else {
          // If API doesn't return url, the user is redirecting to /login
          history.push('/login');
        }
      })
      .catch(err => {
        // if the error makes it so we cannot have a proper response, the service is unavailable
        if (err && !err.response) {
          setLoadingInProgress(false);
          setErrorMessage("Le service n'est pas disponible");
        } else if (err.response.status === 400 && err.response.data.message === 'Invalid token') {
          // If user uses twice the same reset password email (token is valid only once)
          setLoadingInProgress(false);
          setErrorMessage(
            'Ce lien a déjà été utilisé et est désormais invalide, merci de faire une nouvelle demande.',
          );
        } else if (err.response.status === 400) {
          setLoadingInProgress(false);
          setErrorMessage(
            'Votre mot de passe doit contenir 12 caractères minimum, au moins une minuscule, une majuscule et un chiffre.',
          );
        } else {
          setLoadingInProgress(false);
          setErrorMessage('Une erreur est survenue. Veuillez réessayer.');
        }
      });
  };

  const canWeSubmitForm = () => {
    const pwd1Feedback = checkFirstPasswordField(
      document.getElementById('login-form__set-password-1').value,
    );
    const pwd2Feedback = checkSecondPasswordField(
      document.getElementById('login-form__set-password-2').value,
    );

    setFeedbackField1(pwd1Feedback);
    setFeedbackField2(pwd2Feedback);

    return pwd1Feedback.level === '' && pwd2Feedback.level === '';
  };

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        if (canWeSubmitForm()) setPassword();
      }}
      className="login__form"
    >
      <p className="login__form-description">
        Veuillez personnaliser votre nouveau mot de passe pour pouvoir continuer.
      </p>

      {/* 1st PASSWORD INPUT FIELD */}
      <InputField
        topClass="login__input-field form-group"
        type="password"
        cssId="login-form__set-password-1"
        label="Saisissez votre mot de passe"
        tooltipContent="Pour plus de sécurité choisissez un mot de passe qui ne contienne pas d’informations personnelles (nom, prénom, date de naissance, etc.) ni de suites trop simples (1234, AZERTY, 1A2B3C, etc.). Un mot de passe sécurisé est un mot de passe que vous ne notez pas et ne communiquez à personne."
        feedback={feedbackField1}
        passwordCanBeShown={true}
      />

      {/* 2nd PASSWORD INPUT FIELD */}
      <InputField
        topClass="login__input-field form-group"
        type="password"
        cssId="login-form__set-password-2"
        label="Confirmez votre nouveau mot de passe"
        feedback={feedbackField2}
        passwordCanBeShown={true}
      />

      {/* POTENTIAL ERROR MESSAGE */}
      {errorMessage ? <div className="login__main__error alert-danger">{errorMessage}</div> : null}
      <div>
        En cliquant sur le bouton « Enregistrer », vous acceptez nos{' '}
        <a href="/cgu" target="_blank" rel="noopener noreferrer">
          conditions générales d’utilisation
        </a>
        , et reconnaissez que vos données seront utilisées conformément à ce qui est décrit dans
        notre{' '}
        <a href="/legal-notice" target="_blank" rel="noopener noreferrer">
          Politique de Confidentialité
        </a>
        .
      </div>
      {/* SUBMIT BUTTON */}
      <div className="login__main__footer">
        {loadingInProgress ? (
          <SpinnerWrapper isLoading={loadingInProgress} />
        ) : (
          <Button
            text="Enregistrer"
            cssId="set-pwd-form__submit-btn"
            onClick={() => {
              // NEEDED FOR IE11 !
              if (canWeSubmitForm()) setPassword();
            }}
          />
        )}
      </div>
    </form>
  );
};

SetPassword.propTypes = {
  // user id (utorize) to set password to correct user
  userId: PropTypes.number,
  setOriginalJourneyId: PropTypes.func,
  setJourney: PropTypes.func,
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  callback: PropTypes.func,
  user: PropTypes.object,
  setUserEmail: PropTypes.func,
};

export default SetPassword;
