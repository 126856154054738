// Const
import * as documentsConst from '../const/documents.const';
import * as CatalizrConstants from '../../utils/CatalizrConstants';
import * as investmentConst from '../const/investment.const';

// Api
import {
  getInvestmentById,
  getInvestmentLinkById,
  getInvestmentByLinkId,
} from '../../api/investment.api';

import { getCurrentUserType } from '../../utils/JourneyUtils';

import { retrieveDocumentsArchive } from '../../api/document.api';

// Actions
import { initInvestor } from './investor.actions';
import { initInvestment } from './investment.actions';
import {
  setCompanyData,
  resetSelectedCompany,
  setCompanyKindProviderData,
} from './company.actions';
import { setFinancialBrokerData } from './financial-broker.actions';
import {
  getFurthestStep,
  getOperationComments,
  getEmails,
  getLatestStep,
} from '../../../pages/dashboard/common/actions/dashboard.actions';
import { fetchBankKit } from './bank.actions';

// Utils
import { getCurrentStage, isUserCompany, isUserBank } from '../../utils/JourneyUtils';
import { DocumentTypes } from '../../utils/documentTypes';
import { alphabeticalSort, getDocsForPreview } from '../../utils/CatalizrUtils';
import {
  initInvestorCategorization,
  initInvestorStatuses,
} from '../../../pages/invest-details/actions/invest-details-investment.actions';
import { OPERATION_STATUSES } from '../../utils/CatalizrConstants';

//---------------------------------------------------------------------------------------------
// REDUX ACTIONS
//---------------------------------------------------------------------------------------------

const initDocumentsPending = () => ({ type: documentsConst.INIT_DOCUMENTS_PENDING });
const initDocumentsFulfilled = () => ({ type: documentsConst.INIT_DOCUMENTS_FULFILLED });
const initDocumentsRejected = () => ({ type: documentsConst.INIT_DOCUMENTS_REJECTED });

const initDocumentsArchivePending = () => ({ type: documentsConst.INIT_DOCUMENTS_ARCHIVE_PENDING });
const initDocumentsArchiveFulfilled = () => ({
  type: documentsConst.INIT_DOCUMENTS_ARCHIVE_FULFILLED,
});
const initDocumentsArchiveRejected = () => ({
  type: documentsConst.INIT_DOCUMENTS_ARCHIVE_REJECTED,
});

export const setUploadStart = () => ({ type: documentsConst.SET_UPLOAD_START });
export const setUploadStop = () => ({ type: documentsConst.SET_UPLOAD_STOP });
export const initStateDocument = () => ({ type: documentsConst.INIT_DOCUMENTS_STATE });

const setBeginDocuments = documents => ({
  type: documentsConst.SET_BEGIN_DOCUMENTS_LIST,
  documents,
});

const setUpdateDocuments = documents => ({
  type: documentsConst.SET_UPDATE_DOCUMENTS_LIST,
  documents,
});

const setPreviewDocuments = documents => ({
  type: documentsConst.SET_PREVIEW_DOCUMENTS_LIST,
  documents,
});

const getInvestmentLinksDetails = investment => {
  return {
    type: investmentConst.GET_INVESTMENT_LINKS_DETAILS,
    investment,
  };
};

//---------------------------------------------------------------------------------------------

/**
 * Get documents to show in dashboard investment
 * @param {*} investmentId
 */
export const getDocumentsByInvestment = investmentId => (dispatch, getState) => {
  // Get first investment with investment id
  dispatch(initDocumentsPending());
  return getInvestmentById(investmentId, ['investor'])
    .then(async investment => {
      let investmentInfos = investment.data.data;

      // add cfi_code for company
      investmentInfos.company = {
        ...investmentInfos.company,
        cfi_code: investmentInfos.cfi_code,
      };

      // SET INVESTMENT DATAS
      await dispatch(initInvestment(investmentInfos));
      // SET INVESTOR DATAS
      await dispatch(initInvestor(investmentInfos.investors[0]));
      // SET COMPANY DATAS
      await dispatch(resetSelectedCompany());
      await dispatch(setCompanyData(investmentInfos.company));
      await dispatch(setCompanyKindProviderData(investmentInfos.company_a));
      // SET FINANCIAL BROKER DATAS : specific to bond operations
      if (CatalizrConstants.OPERATION_TYPE_BOND === investmentInfos.operation_type) {
        // financialBroker is attached to the company and therefore related data are stored into company
        await dispatch(setFinancialBrokerData(investmentInfos.company));
      }
      // GET COMMENTS
      if (isUserBank()) {
        await dispatch(getOperationComments(investmentInfos.id));
      }
      // GET EMAILS
      await dispatch(getEmails(investmentInfos.investment_link));
      // GET FURTHEST STEP to disable button in dashboard if user is waiting for docusign callback
      await dispatch(getFurthestStep(investmentInfos.investment_link));
      // GET LATEST STEP if investment is cancelled/closed to display cancelled or closed date in accordion
      if (
        investmentInfos.status === OPERATION_STATUSES.CANCELLED ||
        investmentInfos.status === OPERATION_STATUSES.INVESTMENT_CLOSED
      ) {
        await dispatch(getLatestStep(investmentInfos.investment_link));
      }

      // GET BANK INIT according to URL for company dashboard
      if (isUserCompany()) {
        await dispatch(fetchBankKit());
      }
      const userType = getCurrentUserType().toUpperCase();
      // GET DOCUMENTS
      let docs = investmentInfos.documents;
      if (investmentInfos.investor.is_a_corporation === 0) {
        docs = docs.filter(document =>
          document.type_document !== DocumentTypes.PROOF_OF_AUTHORITY && document.not_displayed_for
            ? !document.not_displayed_for.includes(userType)
            : true,
        );
      } else {
        docs = docs.filter(document =>
          document.not_displayed_for ? !document.not_displayed_for.includes(userType) : true,
        );
      }
      const documentsForStage1 = docs.filter(doc => {
        return (
          doc.stage.includes(1) &&
          (doc.type_document !== DocumentTypes.RETURN_ATTESTATION ||
            doc.type_document !== DocumentTypes.RETURN_ATTESTATION_APPORT_TITRES)
        );
      });
      documentsForStage1.sort((doc1, doc2) => alphabeticalSort(doc1.name, doc2.name));
      await dispatch(setBeginDocuments(documentsForStage1));

      const documentsForStage2 = docs.filter(doc => {
        return doc.stage.includes(2) && doc.type_document !== DocumentTypes.ORDRE_MOUVEMENT;
      });
      documentsForStage2.sort((doc1, doc2) => alphabeticalSort(doc1.name, doc2.name));

      await dispatch(setUpdateDocuments(documentsForStage2));

      await dispatch(initDocumentsFulfilled());

      const user = getState().user;
      const { investment_link } = getState().investment;
      const { filteredData: previewDocs, fullResult: investmentDetails } = await getDocsForPreview(
        investment_link,
        {
          userType: user.type,
        },
      );
      await dispatch(getInvestmentLinksDetails(investmentDetails));

      await dispatch(setPreviewDocuments(previewDocs));
      // init investor categorization
      initInvestorCategorization(investmentInfos, dispatch);
      // init investor statuses
      initInvestorStatuses(investmentInfos, dispatch);
    })
    .catch(error => {
      console.error('Error on getDocumentsByInvestment:', error);
      dispatch(initDocumentsRejected());
    });
};

/**
 * Retrieves the data related to the documents to upload for a given investmentLinkId.
 * Populates the default reducer with the resulting data processed (into prop : documentsToUpload)
 * @param {string} investmentLinkId the ID of the investmentLink
 * @void
 */
export const loadDocumentsToUpload =
  (investmentLinkId, isDraftMode = false) =>
  async dispatch => {
    dispatch(initDocumentsPending());

    try {
      const investmentData = await getInvestmentByLinkId(investmentLinkId, {
        stage: getCurrentStage(),
        isDraftMode,
      });

      if (investmentData && investmentData.data && investmentData.data.data) {
        dispatch(initInvestment(investmentData.data.data));
        if (getCurrentStage() === 2) {
          dispatch(setCompanyData(investmentData.data.data.company));
        }
        // success
        dispatch(initDocumentsFulfilled());
        return investmentData.data.data;
      }
    } catch (error) {
      // reset docs & loader if an error occured
      dispatch(initDocumentsRejected());
      if (error && error.response && error.response.status === 500) {
        throw error;
      } else {
        throw new Error(`Error while getting documents to upload for invesmentlink - ${error}`);
      }
    }
  };

/**
 * Returns the uploaded attestation letter (RETURN_ATTESTATION) if found for the given investmentLinkId
 * @param {object} investmentLinkId the ID of the investmentLink to process.
 * @returns {object} the attestation letter uploaded by the client otherwise null.
 */
export const getUploadedAttestationLetterDoc = async investmentLinkId => {
  let uploadedAttestationLetterDoc = null;
  const investmentLink = (await getInvestmentLinkById(investmentLinkId)).data;
  if (investmentLink && investmentLink.documents) {
    uploadedAttestationLetterDoc = investmentLink.documents.find(
      doc =>
        (doc.type_document === DocumentTypes.RETURN_ATTESTATION ||
          doc.type_document === DocumentTypes.RETURN_ATTESTATION_APPORT_TITRES) &&
        doc.uploaded_date,
    );
  }
  return uploadedAttestationLetterDoc;
};

export const generateDocumentsArchive = operationId => async dispatch => {
  dispatch(initDocumentsArchivePending());
  try {
    const archive = await retrieveDocumentsArchive(operationId);
    dispatch(initDocumentsArchiveFulfilled());
    return archive;
  } catch (error) {
    dispatch(initDocumentsArchiveRejected());
    throw new Error(`Error while getting documents archive for operation ${operationId} ${error}`);
  }
};
